<template>
  <CommonLayout class="page_fullscreen">
    <div class="content">
      <h2>404</h2>
      <h1>Страница не найдена</h1>
      <button @click="goHome" class="button button-default button_big">
        Вернуться
      </button>
    </div>
  </CommonLayout>
</template>

<script>
import CommonLayout from "@/layout/CommonLayout.vue";

export default {
  components: {
    CommonLayout,
  },
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding-top: 100px;
  padding-bottom: 50px;
  text-align: center;
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 400px;
    line-height: 463px;
    letter-spacing: 0.055em;
    color: #5cffed;
    margin: 0;
    // margin-top: -200px;
    margin-top: -130px;
  }
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 70px;
    line-height: 81px;
    letter-spacing: 0.005em;
    color: #009382;
    margin: 0;
  }
  button {
    margin-top: 100px;
  }
}
</style>
